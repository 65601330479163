import React, { useEffect, useReducer, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-next';

import { SpotlightTransition } from '@atlaskit/onboarding';
import { usePageLayoutResize } from '@atlaskit/page-layout';

import { MessageDeliveryStatus } from '@atlassian/ipm-choreographer';

import { useChoreographerAssets } from '@confluence/choreographer-services';

import SpotlightImage from '../assets/spotlightImage.svg';
import { i18n } from '../sampleContentI18n';
import { useSpotlight } from '../hooks/useSpotlight';
import { useAnalytics } from '../hooks/useAnalytics';
import { useMountEffect } from '../hooks/useMountEffect';

type ChoreographedSpotlightProps = Pick<
	ReturnType<typeof useSpotlight>,
	'dismiss' | 'setHasSpotlightShown'
>;

const ChoreographedSpotlight = ({ dismiss, setHasSpotlightShown }: ChoreographedSpotlightProps) => {
	const { formatMessage } = useIntl();
	const { Spotlight } = useChoreographerAssets();
	const scrollRef = useRef<null | HTMLDivElement>(null);
	const [_, forceRerender] = useReducer((x) => x + 1, 0);

	const { isLeftSidebarCollapsed, expandLeftSidebar } = usePageLayoutResize();
	const { fireUIAnalyticsEvent, fireScreenAnalyticsEvent, fireTrackAnalyticsEvent } =
		useAnalytics();

	useEffect(() => {
		isLeftSidebarCollapsed && expandLeftSidebar();
	}, [isLeftSidebarCollapsed, expandLeftSidebar]);

	useMountEffect(() => {
		fireTrackAnalyticsEvent({
			actionSubject: 'sample-content-spotlight',
			action: 'mounted',
		});
		return () => {
			fireUIAnalyticsEvent({
				actionSubject: 'sample-content-spotlight',
				action: 'unmounted',
			});
		};
	});
	useEffect(() => {
		setTimeout(() => {
			scrollRef.current?.scrollIntoView();
			// Used to force spotlight rerender after scroll
			forceRerender();
		}, 2000);
	}, []);

	const handleClickDismiss = () => {
		fireUIAnalyticsEvent({
			actionSubject: 'sample-content-spotlight',
			action: 'dismissed',
		});
		dismiss();
	};

	return (
		<div ref={scrollRef}>
			<SpotlightTransition>
				<Spotlight
					heading={`${formatMessage(i18n.spotlightHeadingUpdated)} 🎉`}
					key="pagetree-spotlight-sample-content"
					messageId="pagetree-spotlight-sample-content"
					messageType="engagement"
					target="pagetree"
					targetRadius={3}
					onMessageDisposition={(message) => {
						if (message === MessageDeliveryStatus.STARTED) {
							setHasSpotlightShown();
							fireScreenAnalyticsEvent({
								name: 'sample-content-spotlight',
								action: 'viewed',
							});
						}
					}}
					dialogPlacement="right top"
					dialogWidth={275}
					image={SpotlightImage}
					actions={[
						{
							appearance: 'subtle',
							onClick: handleClickDismiss,
							text: formatMessage(i18n.spotlightDismissButton),
						},
					]}
				>
					<FormattedMessage {...i18n.spotlightBody} />
				</Spotlight>
			</SpotlightTransition>
		</div>
	);
};

export const PageTreeSpotlight = ({
	spaceKey,
	isLoading,
	contentId,
}: {
	spaceKey?: string;
	isLoading: boolean;
	contentId?: string;
}) => {
	const { areSpotlightConditionsMet, dismiss, setHasSpotlightShown } = useSpotlight({
		isTargetReady: !isLoading,
		spaceKey,
		contentId,
	});

	const { fireTrackAnalyticsEvent } = useAnalytics();
	useMountEffect(() => {
		fireTrackAnalyticsEvent({
			actionSubject: 'sample-content-spotlight',
			action: 'conditions evaluated',
		});
	});
	return areSpotlightConditionsMet ? (
		<ChoreographedSpotlight dismiss={dismiss} setHasSpotlightShown={setHasSpotlightShown} />
	) : null;
};
